em {
    font-style: normal;
}

#source  {
    /*color: #CD5149;*/
    font-style: italic;
}

#target {
    /*color: #49CD7E;*/
    font-weight: bolder;
}