.ratings {
    position: relative;
    display: flex;
    justify-content: center;
    color: #465A7A;
    overflow: hidden;
}
.full-stars {

}
.empty-stars:before, .full-stars:before {
    content:"\2605\2605\2605\2605\2605";
    /*content:"\2605\2605\2605";*/
    font-size: 14pt;
}


/*.empty-stars:before {*/
/*    -webkit-text-stroke: 1px #848484;*/
/*}*/
/*.full-stars:before {*/
/*    -webkit-text-stroke: 1px orange;*/
/*}*/