html {
  /* To make use of full height of page*/
  min-height: 100%;
  margin: 0;
  overflow: hidden;
}
body {
  min-height: 100%;
  margin: 0;
  font-family: 'Roboto', sans-serif !important;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
